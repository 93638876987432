




















import { Component, Vue } from 'vue-property-decorator';

import { User } from '@/interfaces/models/User';
import UserService from '@/services/UserService';
import { namespace } from 'vuex-class';

const Auth = namespace('Auth');

@Component
export default class Profile extends Vue {
  @Auth.State('user')
  private currentUser!: User;

  private userService = UserService;

  private user: User | null = null;
  private nameLetters: string = '';

  created() {
    if (!this.currentUser || this.currentUser == null) {
      this.$router.push('/login');
    }

    this.userService.profile().then(
      (response) => {
        if (response && response.status === 200 && response.data && response.data.user) {
          this.user = response.data.user;
          if (this.user.name) {
            this.nameLetters = this.nameLetters.concat(this.user.name.charAt(0).trim());
          }
          if (this.user.surname) {
            this.nameLetters = this.nameLetters.concat(this.user.surname.charAt(0).trim());
          }
        }
      },
      (err) => {
        console.error(err);
      },
    );
  }
}
