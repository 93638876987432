import axios from 'axios';
import authHeader from './Auth/AuthHeader';
import { environment } from '@/environments/environment';
import { DTO } from '@/interfaces/DTO';
import { UserServiceInterface } from '@/interfaces/services/UserServiceInterface';

class UserService implements UserServiceInterface {
  profile = () => {
    return axios.get<DTO.User.Responses.Get>(`${environment.baseUrl}/user/profile`, {
      headers: authHeader(),
    });
  };
}

export default new UserService();
